import axios, { AxiosResponse } from "axios";

import { getCurrentUserToken } from "./auth.service";
import { ScrapingError } from "../utils/scraping.error";
import { DownloadReportType } from "../hooks/useDownloadReports";
import { ProjectModel } from "import-automation-tool-backend/src/models/project.model";
import {JobNotificationModel} from "import-automation-tool-backend/src/models/notifications.model";

const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

interface RequestArgs {
  url: string;
  headers: { [header: string]: string };
}

export default class ApiService {
  private static SCRAPING_ERROR_STATUS = "SCRAPING_ERROR";

  private async buildRequest(uri: string): Promise<RequestArgs> {
    const token = await getCurrentUserToken();

    return {
      url: `${REACT_APP_BACKEND_URL}${uri}`,
      headers: { Authorization: token },
    };
  }

  private async executeRequest<T>(fn: () => Promise<T>): Promise<T> {
    try {
      const result = await fn();
      return result;
    } catch (error: any) {
      let message = "";

      if (axios.isAxiosError(error)) {
        message =
          error.response && error.response.data
            ? JSON.stringify(error.response.data)
            : "";
      }

      throw new Error(message || error);
    }
  }

  async createProject(ecmEnv: string, project: any) {
    try {
      const token = await getCurrentUserToken();

      await axios({
        url: `${REACT_APP_BACKEND_URL}/ecm/${ecmEnv}/projects`,
        method: "put",
        headers: { Authorization: token },
        data: JSON.stringify(project),
      });
    } catch (error: any) {
      throw new Error(
        error && error.response && error.response.data
          ? JSON.stringify(error.response.data)
          : error,
      );
    }
  }

  async updateProjectSubscribers(project: ProjectModel) {
    try {
      const token = await getCurrentUserToken();

      await axios({
        url: `${REACT_APP_BACKEND_URL}/ecm/${project.ecmEnv}/projects/${project.id}/subscribers`,
        method: "put",
        headers: { Authorization: token },
        data: JSON.stringify({ subscribers: project.subscribers ?? [] }),
      });
    } catch (error: any) {
      throw new Error(
        error && error.response && error.response.data
          ? JSON.stringify(error.response.data)
          : error,
      );
    }
  }

  async listProjects(ecmEnv: string) {
    try {
      const token = await getCurrentUserToken();

      const result = await axios(
        `${REACT_APP_BACKEND_URL}/ecm/${ecmEnv}/projects`,
        {
          headers: { Authorization: token },
        },
      );
      return result.data;
    } catch (error: any) {
      throw new Error(
        error && error.response && error.response.data
          ? JSON.stringify(error.response.data)
          : error,
      );
    }
  }

  async deleteProject(ecmEnv: string, projectId: string) {
    try {
      const token = await getCurrentUserToken();

      await axios({
        url: `${process.env.REACT_APP_BACKEND_URL}/ecm/${ecmEnv}/projects/${projectId}`,
        method: "delete",
        headers: { Authorization: token },
      });
    } catch (error: any) {
      throw new Error(
        error && error.response && error.response.data
          ? JSON.stringify(error.response.data)
          : error,
      );
    }
  }

  async listEcmEnvs() {
    try {
      const token = await getCurrentUserToken();

      const result = await axios({
        url: `${process.env.REACT_APP_BACKEND_URL}/ecm/environments`,
        method: "get",
        headers: { Authorization: token },
      });

      return result.data;
    } catch (error: any) {
      throw new Error(
        error && error.response && error.response.data
          ? JSON.stringify(error.response.data)
          : error,
      );
    }
  }

  async listEcmDealers(ecmEnv: string) {
    try {
      const token = await getCurrentUserToken();

      const result = await axios(
        `${process.env.REACT_APP_BACKEND_URL}/ecm/${ecmEnv}/dealers`,
        {
          headers: { Authorization: token },
        },
      );

      return result.data;
    } catch (error: any) {
      throw new Error(
        error && error.response && error.response.data
          ? JSON.stringify(error.response.data)
          : error,
      );
    }
  }

  async getEcmDealer(ecmEnv: string, dealerId: string) {
    try {
      const token = await getCurrentUserToken();

      const result = await axios(
        `${process.env.REACT_APP_BACKEND_URL}/ecm/${ecmEnv}/dealers/${dealerId}`,
        {
          headers: { Authorization: token },
        },
      );

      return result.data;
    } catch (error: any) {
      throw new Error(
        error && error.response && error.response.data
          ? JSON.stringify(error.response.data)
          : error,
      );
    }
  }

  async listDealerAccounts(ecmEnv: string, dealerId: string) {
    try {
      const token = await getCurrentUserToken();

      const result = await axios(
        `${process.env.REACT_APP_BACKEND_URL}/ecm/${ecmEnv}/dealers/${dealerId}/accounts`,
        {
          headers: { Authorization: token },
        },
      );

      return result.data;
    } catch (error: any) {
      throw new Error(
        error && error.response && error.response.data
          ? JSON.stringify(error.response.data)
          : error,
      );
    }
  }

  async listActions(ecmEnv: string, projectId: string, accountId: string) {
    try {
      const token = await getCurrentUserToken();

      const result = await axios(
        `${process.env.REACT_APP_BACKEND_URL}/ecm/${ecmEnv}/projects/${projectId}/account/${accountId}/actions`,
        {
          headers: { Authorization: token },
          method: "GET",
        },
      );

      return result.data;
    } catch (error: any) {
      throw new Error(
        error && error.response && error.response.data
          ? JSON.stringify(error.response.data)
          : error,
      );
    }
  }

  async getMarketplaceInventory(
    ecmEnv: string,
    projectId: string,
    accountId: string,
    credentialsAccount: string,
    credentialsEnv: string,
  ) {
    try {
      const token = await getCurrentUserToken();

      const result = await axios(
        `${process.env.REACT_APP_BACKEND_URL}/ecm/${ecmEnv}/projects/${projectId}/account/${accountId}/marketplaceInventory`,
        {
          headers: { Authorization: token },
          method: "PUT",
          data: {
            credentialsAccount: credentialsAccount,
            credentialsEnv: credentialsEnv,
          },
        },
      );

      return result.data;
    } catch (error: any) {
      throw new Error(
        error && error.response && error.response.data
          ? JSON.stringify(error.response.data)
          : error,
      );
    }
  }

  async getEcmInventory(ecmEnv: string, projectId: string, accountId: string) {
    try {
      const token = await getCurrentUserToken();

      const result = await axios(
        `${process.env.REACT_APP_BACKEND_URL}/ecm/${ecmEnv}/projects/${projectId}/account/${accountId}/ecmInventory`,
        {
          headers: { Authorization: token },
          method: "PUT",
        },
      );

      return result.data;
    } catch (error: any) {
      throw new Error(
        error && error.response && error.response.data
          ? JSON.stringify(error.response.data)
          : error,
      );
    }
  }

  async removeListings(ecmEnv: string, projectId: string, accountId: string) {
    try {
      const token = await getCurrentUserToken();

      const result = await axios(
        `${process.env.REACT_APP_BACKEND_URL}/ecm/${ecmEnv}/projects/${projectId}/account/${accountId}/removeListings`,
        {
          headers: { Authorization: token },
          method: "PUT",
        },
      );

      return result.data;
    } catch (error: any) {
      throw new Error(
        error && error.response && error.response.data
          ? JSON.stringify(error.response.data)
          : error,
      );
    }
  }

  async massMergeProducts(
    ecmEnv: string,
    projectId: string,
    accountIds: string[],
    mergeMode: number,
  ) {
    try {
      const token = await getCurrentUserToken();

      const result = await axios(
        `${process.env.REACT_APP_BACKEND_URL}/ecm/${ecmEnv}/projects/${projectId}/massMergeProducts`,
        {
          headers: { Authorization: token },
          method: "PUT",
          data: { accountIds: accountIds, mode: mergeMode },
        },
      );

      return result.data;
    } catch (error: any) {
      throw new Error(
        error && error.response && error.response.data
          ? JSON.stringify(error.response.data)
          : error,
      );
    }
  }

  async marketplaceImport(
    ecmEnv: string,
    projectId: string,
    accountId: string,
    retrieveStatuses: string[] | null,
    importStatuses: string[] | null,
    productIdPrefix: string,
    customIds: string[] | null,
    credentialsAccount: string,
    credentialsEnv: string,
    isSingularJob: boolean,
  ) {
    try {
      const token = await getCurrentUserToken();

      const result = await axios(
        `${process.env.REACT_APP_BACKEND_URL}/ecm/${ecmEnv}/projects/${projectId}/account/${accountId}/marketplaceImport`,
        {
          headers: { Authorization: token },
          method: "PUT",
          data: {
            retrieveStatuses: retrieveStatuses,
            importStatuses: importStatuses,
            productIdPrefix: productIdPrefix,
            customIds: customIds,
            credentialsAccount: credentialsAccount,
            credentialsEnv: credentialsEnv,
            batchMode: isSingularJob ? "singular" : "batch",
          },
        },
      );

      return result.data;
    } catch (error: any) {
      const data = error.response?.data;

      if (data?.type === ApiService.SCRAPING_ERROR_STATUS) {
        throw new ScrapingError(data.message, data.details?.statusPageUrl);
      }

      throw new Error(data ? JSON.stringify(data) : error);
    }
  }

  async marketplaceAnonymousImport(
    ecmEnv: string,
    projectId: string,
    anonymousImportMarketplace: string,
    marketplaceDealerId: string,
    customIds: string[] | null,
  ) {
    try {
      const token = await getCurrentUserToken();

      const result = await axios(
        `${process.env.REACT_APP_BACKEND_URL}/ecm/${ecmEnv}/projects/${projectId}/marketplaceAnonymousImport`,
        {
          headers: { Authorization: token },
          method: "PUT",
          data: {
            marketplaceDealerId: marketplaceDealerId,
            customIds: customIds,
            marketplace: anonymousImportMarketplace,
          },
        },
      );

      return result.data;
    } catch (error: any) {
      const data = error.response?.data;

      if (data?.type === ApiService.SCRAPING_ERROR_STATUS) {
        throw new ScrapingError(data.message, data.details?.statusPageUrl);
      }

      throw new Error(data ? JSON.stringify(data) : error);
    }
  }

  async csvboxImport(
    ecmEnv: string,
    projectId: string,
    csvboxJobId: string
  ) {
    try {
      const token = await getCurrentUserToken();

      const result = await axios(
        `${process.env.REACT_APP_BACKEND_URL}/ecm/${ecmEnv}/projects/${projectId}/csvboxImport`,
        {
          headers: { Authorization: token },
          data: {
            jobId: csvboxJobId
          },
          method: "PUT",
        },
      );

      return result.data;
    } catch (error: any) {
      const data = error.response?.data;
      throw new Error(data ? JSON.stringify(data) : error);
    }
  }

  async getCsvBoxJobData(ecmEnv: string, jobId: string): Promise<JobNotificationModel> {
      const token = await getCurrentUserToken();

      const result = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/ecm/${ecmEnv}/jobId/job-${jobId}/getCsvboxJobNotification`,
          {
            headers: { Authorization: token },
          }
      );

      return result.data;
  }
  async getAnonymousImportData(ecmEnv: string, jobId: string): Promise<JobNotificationModel> {
      const token = await getCurrentUserToken();

      const result = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/ecm/${ecmEnv}/jobId/job-${jobId}/getAnonymousImportJobNotification`,
          {
            headers: { Authorization: token },
          }
      );

      return result.data;
  }

  async marketplaceImportWithFile(
    ecmEnv: string,
    projectId: string,
    accountId: string,
    statuses: string[] | null,
    skipFetch: boolean = false,
  ) {
    try {
      const token = await getCurrentUserToken();

      const result = await axios(
        `${process.env.REACT_APP_BACKEND_URL}/ecm/${ecmEnv}/projects/${projectId}/account/${accountId}/marketplaceImportWithFile`,
        {
          headers: { Authorization: token },
          method: "PUT",
          data: { importStatuses: statuses, skipFetch },
        },
      );

      return result.data;
    } catch (error: any) {
      const data = error.response?.data;

      if (data?.type === ApiService.SCRAPING_ERROR_STATUS) {
        throw new ScrapingError(data.message, data.details?.statusPageUrl);
      }

      throw new Error(data ? JSON.stringify(data) : error);
    }
  }

  async importProductWithCSVFile(
    ecmEnv: string,
    projectId: string,
    accountId: string,
    statuses: string[] | null,
    skipFetch: boolean = false,
  ) {
    try {
      const token = await getCurrentUserToken();

      const result = await axios(
        `${process.env.REACT_APP_BACKEND_URL}/ecm/${ecmEnv}/projects/${projectId}/account/${accountId}/importProductWithCSVFile`,
        {
          headers: { Authorization: token },
          method: "PUT",
          data: { importStatuses: statuses, skipFetch },
        },
      );

      return result.data;
    } catch (error: any) {
      const data = error.response?.data;

      if (data?.type === ApiService.SCRAPING_ERROR_STATUS) {
        throw new ScrapingError(data.message, data.details?.statusPageUrl);
      }

      throw new Error(data ? JSON.stringify(data) : error);
    }
  }

  async uploadFile(file: File, url: string) {
    try {
      await axios(url, {
        headers: {
          "Content-Type": file.type,
        },
        method: "PUT",
        data: await file.arrayBuffer(),
      });
    } catch (e) {
      console.log(e);
      throw new Error("error while uploading file");
    }
  }

  async expectedResultsAction(
    ecmEnv: string,
    projectId: string,
    accountId: string,
    statuses: string[] | null,
  ) {
    try {
      const token = await getCurrentUserToken();

      const result = await axios(
        `${process.env.REACT_APP_BACKEND_URL}/ecm/${ecmEnv}/projects/${projectId}/account/${accountId}/expectedResults`,
        {
          headers: { Authorization: token },
          method: "PUT",
          data: { importStatuses: statuses },
        },
      );

      return result.data;
    } catch (error: any) {
      throw new Error(
        error && error.response && error.response.data
          ? JSON.stringify(error.response.data)
          : error,
      );
    }
  }

  async extractListings(
    ecmEnv: string,
    projectId: string,
    accountId: string,
    credentialsAccount: string,
    credentialsEnv: string,
  ) {
    try {
      const token = await getCurrentUserToken();

      const result = await axios(
        `${process.env.REACT_APP_BACKEND_URL}/ecm/${ecmEnv}/projects/${projectId}/account/${accountId}/extractListings`,
        {
          headers: { Authorization: token },
          method: "PUT",
          data: {
            credentialsAccount: credentialsAccount,
            credentialsEnv: credentialsEnv,
          },
        },
      );

      return result.data;
    } catch (error: any) {
      throw new Error(
        error && error.response && error.response.data
          ? JSON.stringify(error.response.data)
          : error,
      );
    }
  }

  async getExtractedListings(
    ecmEnv: string,
    projectId: string,
    accountId: string,
    actionId: string,
  ) {
    try {
      const token = await getCurrentUserToken();

      const result = await axios(
        `${process.env.REACT_APP_BACKEND_URL}/ecm/${ecmEnv}/projects/${projectId}/account/${accountId}/action/${actionId}/getExtractedProducts`,
        {
          headers: { Authorization: token },
          method: "GET",
        },
      );

      if (!result?.data?.url) {
        throw new Error(`Invalid response [${JSON.stringify(result)}]`);
      }

      return result.data.url;
    } catch (error: any) {
      throw new Error(
        error && error.response && error.response.data
          ? JSON.stringify(error.response.data)
          : error,
      );
    }
  }

  async getMarketplaceNotes(marketplaceCode: string) {
    try {
      const token = await getCurrentUserToken();

      const result = await axios(
        `${process.env.REACT_APP_BACKEND_URL}/marketplace/${marketplaceCode}/notes`,
        {
          headers: { Authorization: token },
          method: "GET",
        },
      );

      return result.data;
    } catch (error: any) {
      throw new Error(
        error && error.response && error.response.data
          ? JSON.stringify(error.response.data)
          : error,
      );
    }
  }

  async getMarketplaceRetrieveStatuses(marketplaceCode: string) {
    try {
      const token = await getCurrentUserToken();

      const result = await axios(
        `${process.env.REACT_APP_BACKEND_URL}/marketplace/${marketplaceCode}/config`,
        {
          headers: { Authorization: token },
          method: "GET",
        },
      );

      return result.data;
    } catch (error: any) {
      throw new Error(
        error && error.response && error.response.data
          ? JSON.stringify(error.response.data)
          : error,
      );
    }
  }

  async getMarketplaceImportStatuses(marketplaceCode: string) {
    try {
      const token = await getCurrentUserToken();

      const result = await axios(
        `${process.env.REACT_APP_BACKEND_URL}/marketplace/${marketplaceCode}/config?getEcmStatuses=true`,
        {
          headers: { Authorization: token },
          method: "GET",
        },
      );

      return result.data;
    } catch (error: any) {
      throw new Error(
        error && error.response && error.response.data
          ? JSON.stringify(error.response.data)
          : error,
      );
    }
  }

  async saveMarketplaceNotes(marketplaceCode: string, note: string) {
    try {
      const token = await getCurrentUserToken();

      const result = await axios(
        `${process.env.REACT_APP_BACKEND_URL}/marketplace/${marketplaceCode}/notes`,
        {
          headers: { Authorization: token },
          method: "POST",
          data: { noteText: note },
        },
      );

      return result.data;
    } catch (error: any) {
      throw new Error(
        error && error.response && error.response.data
          ? JSON.stringify(error.response.data)
          : error,
      );
    }
  }

  async importAnalysis(ecmEnv: string, projectId: string, accountId: string) {
    try {
      const token = await getCurrentUserToken();

      const result = await axios(
        `${process.env.REACT_APP_BACKEND_URL}/ecm/${ecmEnv}/projects/${projectId}/account/${accountId}/importAnalysis`,
        {
          headers: { Authorization: token },
          method: "PUT",
        },
      );

      return result.data;
    } catch (error: any) {
      throw new Error(
        error && error.response && error.response.data
          ? JSON.stringify(error.response.data)
          : error,
      );
    }
  }

  async downloadReport(options: {
    types: DownloadReportType[];
    ecmEnv: string;
    projectId: string;
    accountId: string;
    actionId: string;
    cancelSignal?: AbortSignal;
  }) {
    return this.executeRequest(async () => {
      const query = new URLSearchParams();

      for (const type of options.types) {
        query.append("type", type);
      }

      const { url, headers } = await this.buildRequest(
        `/ecm/${options.ecmEnv}/projects/${options.projectId}/account/${
          options.accountId
        }/actions/${options.actionId}/reports?${query.toString()}`,
      );

      const result: AxiosResponse<{ url: string }> = await axios(url, {
        headers,
        method: "GET",
        signal: options.cancelSignal,
      });

      return result.data;
    });
  }

  async listEcmProductAttributes(ecmEnv: string) {
    try {
      const token = await getCurrentUserToken();

      const result = await axios(
        `${process.env.REACT_APP_BACKEND_URL}/ecm/${ecmEnv}/product/attributes`,
        {
          headers: { Authorization: token },
        },
      );

      return result.data;
    } catch (error: any) {
      throw new Error(
        error && error.response && error.response.data
          ? JSON.stringify(error.response.data)
          : error,
      );
    }
  }

  async getLocationByDealerId(ecmEnv: string, dealerId: string) {
    try {
      const token = await getCurrentUserToken();

      const result = await axios(
        `${process.env.REACT_APP_BACKEND_URL}/ecm/${ecmEnv}/dealer/${dealerId}/locations`,
        {
          headers: { Authorization: token },
        },
      );

      return result.data;
    } catch (error: any) {
      throw new Error(
        error && error.response && error.response.data
          ? JSON.stringify(error.response.data)
          : error,
      );
    }
  }

  async getCategoriesTree(ecmEnv: string) {
    try {
      const token = await getCurrentUserToken();

      const result = await axios(
        `${process.env.REACT_APP_BACKEND_URL}/ecm/${ecmEnv}/categories/tree`,
        {
          headers: { Authorization: token },
        },
      );

      return result.data;
    } catch (error: any) {
      throw new Error(
        error && error.response && error.response.data
          ? JSON.stringify(error.response.data)
          : error,
      );
    }
  }

  async terminateJob(
    ecmEnv: string,
    projectId: string,
    accountId: string,
    actionId: string,
    jobId: string,
  ) {
    try {
      const { url, headers } = await this.buildRequest(
        `/ecm/${ecmEnv}/projects/${projectId}/account/${accountId}/actions/${actionId}/terminateJob/${jobId}`,
      );

      await axios(url, {
        headers,
        method: "POST",
      });
    } catch (error: any) {
      throw new Error(
        error && error.response && error.response.data
          ? JSON.stringify(error.response.data)
          : error,
      );
    }
  }
}
