import { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Divider,
  Grid,
  TextField,
  Typography,
  LinearProgress,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import * as DateFns from "date-fns";
import { ActionModel } from "import-automation-tool-backend/src/models/action/action.model";
import { MarketplaceNoteModel } from "import-automation-tool-backend/src/models/marketplaceNote.model";
import ApiService from "../../../services/api.service";

import {
  DownloadReportStatus,
  useDownloadReport,
  DownloadReportType,
} from "../../../hooks/useDownloadReports";
import { SplitButton, SplitOption } from "../../../components/SplitButton";
import { AlertContext } from "../../../views/Main";
import { ALERT_STATUS } from "../../Alert";
import { getDisabledReportTooltip } from "./utils";
import {JobNotificationModel} from "../../../../../backend/src/models/notifications.model";
import {ActionStatusModel} from "import-automation-tool-backend/src/models/action/status.model";

interface ComponentProps {
  item: ActionModel;
  generatePresignedUrl?: (id: string) => void | undefined;
  marketplaceNotes?: MarketplaceNoteModel | null;
  saveMarketplaceNotes?: (note: string) => void | null;
  marketplaceNotesLoading?: boolean | null;
}

function formatDuration(startSeconds?: number, endSeconds?: number): string {
  if (typeof startSeconds !== "number" || typeof endSeconds !== "number") {
    return "N/A";
  } else if (startSeconds === endSeconds) {
    return "a few seconds";
  }

  const duration = DateFns.intervalToDuration({
    start: new Date(startSeconds * 1000),
    end: new Date(endSeconds * 1000),
  });
  return DateFns.formatDuration(duration);
}

export default function ActionItem({
  item,
  generatePresignedUrl,
  marketplaceNotes,
  saveMarketplaceNotes,
  marketplaceNotesLoading,
}: ComponentProps) {
  const alertContext = useContext(AlertContext);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [jobLogsExpanded, setJobLogsExpanded] = useState<boolean>(false);
  const [editNotes, setEditNotes] = useState<boolean>(false);
  const [mpNoteValue, setMpNoteValue] = useState<string>("");
  const {
    downloadReport,
    isLoading: isDownloadingReport,
    state: downloadReportState,
  } = useDownloadReport({
    ecmEnv: item.ecmEnv,
    projectId: item.projectId,
    accountId: item.accountId,
    actionId: item.id,
  });

  const setAlert = alertContext?.setAlert;
  useEffect(
    function handleDownloadReportError() {
      if (
        setAlert &&
        downloadReportState.status === DownloadReportStatus.ERROR
      ) {
        const { errorMessage } = downloadReportState;
        setAlert({
          type: ALERT_STATUS.ERROR,
          message: `Download report error${
            errorMessage ? `: ${errorMessage}` : ""
          }`,
        });
      }
    },
    [downloadReportState, setAlert],
  );

  const displayEcmInventory = (data: any) => {
    const result = calculateTotal(data?.counts);
    const statusesDict = result.statusMap;
    const statusNames = result.statusNames;

    return (
      <Grid container>
        <Grid item xs={12}>
          {statusNames.length
            ? statusNames.map(item => {
                return (
                  <div key={item}>
                    {item}: {statusesDict[item]}
                  </div>
                );
              })
            : "Counts not available"}
        </Grid>
      </Grid>
    );
  };

  const displayMarketplaceInventory = (data: any) => {
    const marketplaceNotesText = marketplaceNotes?.noteText;

    const result = calculateTotal(data?.counts);
    const statusesDict = result.statusMap;
    const statusNames = result.statusNames;

    return (
      <Grid container>
        <Grid item xs={6}>
          {statusNames.length
            ? statusNames.map(item => {
                return (
                  <div key={item}>
                    {item}: {statusesDict[item]}
                  </div>
                );
              })
            : "Counts not available"}
        </Grid>
        <Grid item xs={6}>
          <span
            onClick={() => {
              if (!editNotes) {
                setEditNotes(true);
              }
            }}
          >
            {!editNotes ? (
              <>
                <div style={{ whiteSpace: "pre-wrap" }}>
                  {marketplaceNotesText}{" "}
                </div>
                <Button variant="text">
                  {marketplaceNotesText ? "edit MP Notes" : "add MP Notes"}
                </Button>
              </>
            ) : (
              <span>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="outlined-multiline-static"
                    multiline
                    rows={4}
                    defaultValue={marketplaceNotesText}
                    onChange={value => {
                      setMpNoteValue(value.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} textAlign="end">
                  <Button
                    onClick={() => {
                      setEditNotes(false);
                      if (saveMarketplaceNotes) {
                        saveMarketplaceNotes(mpNoteValue);
                      }
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    onClick={() => {
                      setEditNotes(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </span>
            )}
          </span>
        </Grid>
      </Grid>
    );
  };

  const displayRemoveProducts = (data: any) => {
    const response = data?.response || null;
    const message: string = response?.message || "N/A";
    const results: { product_id: string; message: string; error: string }[] =
      response && response.results && Array.isArray(response.results)
        ? response.results
        : [];
    const successLogs = results.filter(logItem => !logItem.error);
    const errorLogs = results.filter(logItem => logItem.error);

    item.logs = errorLogs.map(logItem => {
      return {
        message: `Can't remove listing with id [${
          logItem?.product_id || "N/A"
        }], error [${logItem?.error || "N/A"}]`,
        isError: false,
      };
    });

    return (
      <Grid container>
        <Grid item xs={12}>
          <div>ECM Message: {message}</div>
          <div>{successLogs.length} listings deleted</div>
          <div>
            {errorLogs.length} error(s) happened when try to remove listings
          </div>
        </Grid>
      </Grid>
    );
  };

  const displayImportAnalysis = (data: any) => {
    const importData = data?.import || null;
    const expectedData = data?.expected || null;
    const ecmInventory = data?.inventory || null;

    const expectedTotal = Object.getOwnPropertyNames(
      expectedData?.expectedImports || {},
    ).reduce((accumulator: number, statusName: string) => {
      if ("total" === statusName.toLocaleLowerCase()) {
        return accumulator;
      } else {
        return accumulator + expectedData.expectedImports[statusName];
      }
    }, 0);
    const ecmInventoryTotalBeforeImport = Object.getOwnPropertyNames(
      expectedData?.ecmInventory || {},
    ).reduce((accumulator: number, statusName: string) => {
      return accumulator + expectedData.ecmInventory[statusName];
    }, 0);
    const ecmInventoryTotalAfterImport = Object.getOwnPropertyNames(
      ecmInventory?.counts || {},
    ).reduce((accumulator: number, statusName: string) => {
      return accumulator + ecmInventory.counts[statusName];
    }, 0);

    return (
      <Grid container>
        <Grid item xs={12}>
          <div>
            Marketplace integration extracted {importData?.itemsCount || 0}{" "}
            listings. {expectedTotal} were expected.
          </div>
          <div>
            {importData?.productsStatuses?.success || 0} listing were imported
            successfully.
          </div>
          <div>
            eCM inventory has {ecmInventoryTotalAfterImport} listings.{" "}
            {ecmInventoryTotalBeforeImport + expectedTotal} were expected
          </div>
          <div>
            eCM inventory has {ecmInventory?.counts?.active || 0} active
            listings.{" "}
            {(expectedData?.ecmInventory?.active || 0) +
              (expectedData?.expectedImports?.active || 0)}{" "}
            were expected
          </div>
          <div>
            eCM inventory has {ecmInventory?.counts?.inactive || 0} inactive
            listings.{" "}
            {(expectedData?.ecmInventory?.inactive || 0) +
              (expectedData?.expectedImports?.inactive || 0)}{" "}
            were expected
          </div>
          <div>
            {importData?.productsStatuses?.failed || 0} import failures were
            encountered.
          </div>
          <div>
            {importData?.productsStatuses?.skipped || 0} items are skipped.
          </div>
          <div>
            {importData?.productsStatuses?.invalid || 0} items are invalid.
          </div>
        </Grid>
      </Grid>
    );
  };

  const importProducts = (data: any) => {
    const extractedProducts = data?.itemsCount || 0;
    const logs = data?.logs || [];

    const result = calculateTotal(data?.productsStatuses);
    const productStatuses = result.statusMap;
    const productStatusesNames = result.statusNames;

    return (
      <Grid container>
        <Grid item xs={12}>
          <div>Extracted products: {extractedProducts}</div>
          <div>Imported products: {productStatusesNames.length ? "" : "0"}</div>
          {productStatusesNames.map(item => {
            return (
              <div key={item} style={{ marginLeft: "10px" }}>
                {item} : {productStatuses[item]}
              </div>
            );
          })}

          {!!logs.length && (
            <span>
              <Grid item xs={12} textAlign="end">
                <Button
                  size="small"
                  onClick={() => setJobLogsExpanded(!jobLogsExpanded)}
                  disabled={!logs || !logs.length}
                  style={{ marginRight: "-8px" }}
                >
                  {!jobLogsExpanded ? "Show Job Logs" : "Hide Job Logs"}
                </Button>
              </Grid>
              <Collapse in={jobLogsExpanded} timeout="auto" unmountOnExit>
                <Divider />
                <CardContent>
                  {logs?.map((log: any, index: number) => (
                    <Typography key={index}>
                      {index + 1}.{log}
                    </Typography>
                  ))}
                </CardContent>
              </Collapse>
            </span>
          )}
        </Grid>
      </Grid>
    );
  };

  const DisplayCSVBoxImport = (ecmEnv: string, data: any, serviceName: string) => {
    const [notificationResponse, setNotificationResponse] = useState<JobNotificationModel | null>(null);
    const [apiError, setApiError] = useState<string | null>(null);
    const [loadingMessage, setLoadingMessage] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
      const maxAttempts = parseInt(process.env.REACT_APP_MAX_ATTEMPTS_ON_ERROR);
      const attemptsDelay = parseInt(process.env.REACT_APP_NOTIFICATION_REFRESH_RATE_MS);
      const attemptsDelayOnError = parseInt(process.env.REACT_APP_NOTIFICATION_REFRESH_RATE_ON_ERROR_MS);

      const fetchData = async (attempt = 1) => {
        try {
          let response: JobNotificationModel;

          if (serviceName === "CSV box") {
            response = await apiService.getCsvBoxJobData(ecmEnv, data.jobId);
          }
          else if (serviceName === "Anonymous marketplace import") {
            response = await apiService.getAnonymousImportData(ecmEnv, data.jobId);
          }

          item.status = response.status as ActionStatusModel;

          if (response.status !== "IN_PROGRESS") {
            setNotificationResponse(response);
            setIsLoading(false);
          }
          else {
            setNotificationResponse(response);
            setIsLoading(false);
            setTimeout(() => fetchData(attempt), attemptsDelay);
          }
        } catch (error: any) {
          item.status = "started";
          if (error.response && error.response.status === 404) {
            if (attempt < maxAttempts) {
              setLoadingMessage(`${serviceName} job is Loading, attempt: ${attempt}...`);
              setTimeout(() => fetchData(attempt + 1), attemptsDelayOnError);
            }
            else {
              setApiError(`${serviceName} job not found after ${attempt} attempts.`);
              setIsLoading(false);
            }
          }
          else {
            setApiError(`An error occurred while trying to fetch ${serviceName} job.`);
            setIsLoading(false);
          }
        }
      };

      fetchData();
    }, [ecmEnv, data.jobId, serviceName]);

    const lastRefresh = (
        <Typography>
            Last refresh: {new Date().toLocaleString()}
        </Typography>
    )

    let returnMsg: string;

    if (isLoading) {
      returnMsg = loadingMessage;
    }
    else if (apiError) {
      returnMsg = apiError;
    }
    else if (!notificationResponse) {
      returnMsg = "CSVBOX job data is empty.";
    }

    if (isLoading || apiError || !notificationResponse) {
      return (
        <Grid container>
          <Grid item xs={12}>
            <Typography>
              {returnMsg}
            </Typography>
            {lastRefresh}
          </Grid>
        </Grid>
      )
    }

    const stats = notificationResponse.journey_overall_stats;

    let logFileUrl = notificationResponse.log_file_url;
    if (logFileUrl && !logFileUrl.startsWith("https://")) {
      logFileUrl = `https://${logFileUrl}`;
    }

    return (
      <Grid container>
        <Grid item xs={12}>
          {notificationResponse.estimated_remaining_time && (
            <Typography>Remaining time: {notificationResponse.estimated_remaining_time}</Typography>
          )}
          {notificationResponse.description && (
            <Typography>Issues with job: {notificationResponse.description}</Typography>
          )}
          {stats.journeys_total >= 0 && (
            <Typography>Journeys total: {stats.journeys_total}</Typography>
          )}
          {stats.journeys_tracked >= 0 && (
            <Typography>Journeys tracked: {stats.journeys_tracked}</Typography>
          )}
          {stats.journeys_remaining >= 0 && (
            <Typography>Journeys remaining: {stats.journeys_remaining}</Typography>
          )}
          {stats.journeys_failed_processing >= 0 && (
            <Typography>
              Journeys failed processing: {stats.journeys_failed_processing}
            </Typography>
          )}
          {stats.journeys_succeeded_processing >= 0 && (
            <Typography>
              Journeys succeeded processing: {stats.journeys_succeeded_processing}
            </Typography>
          )}
          {logFileUrl && (
            <Typography>
              Log file url: <a href={logFileUrl} target="_blank" rel="noopener noreferrer">{logFileUrl}</a>
            </Typography>
          )}
          {lastRefresh}
        </Grid>
      </Grid>
    );
  };

  const extractListings = (data: any) => {
    const extractedProducts = data?.itemsCount || 0;
    const logs = data?.logs || [];

    const result = calculateTotal(data?.ecmStatuses);
    const ecmStatuses = result.statusMap;
    const ecmStatusesNames = result.statusNames;

    return (
      <Grid container>
        <Grid item xs={12}>
          <div>Extracted products: {extractedProducts}</div>
          {ecmStatusesNames.map(statusName => {
            return (
              <div key={statusName} style={{ marginLeft: "10px" }}>
                {statusName} : {ecmStatuses[statusName]}
              </div>
            );
          })}
          {data?.reportFileStatus === "created" && generatePresignedUrl && (
            <span>
              Report:
              <Button
                size="small"
                onClick={() => {
                  generatePresignedUrl(item.id);
                }}
              >
                Download
              </Button>
            </span>
          )}

          {!!logs.length && (
            <span>
              <Grid item xs={12} textAlign="end">
                <Button
                  size="small"
                  onClick={() => setJobLogsExpanded(!jobLogsExpanded)}
                  disabled={!logs || !logs.length}
                  style={{ marginRight: "-8px" }}
                >
                  {!jobLogsExpanded ? "Show Job Logs" : "Hide Job Logs"}
                </Button>
              </Grid>
              <Collapse in={jobLogsExpanded} timeout="auto" unmountOnExit>
                <Divider />
                <CardContent>
                  {logs?.map((log: any, index: number) => (
                    <Typography key={index}>
                      {index + 1}.{log}
                    </Typography>
                  ))}
                </CardContent>
              </Collapse>
            </span>
          )}
        </Grid>
      </Grid>
    );
  };

  const expectedResults = (data: any) => {
    const mpResult = calculateTotal(data?.marketplaceInventory);
    const mpStatuses = mpResult.statusMap;
    const mpStatusesNames = mpResult.statusNames;

    const ecmResult = calculateTotal(data?.ecmInventory);
    const ecmStatuses = ecmResult.statusMap;
    const ecmStatusesNames = ecmResult.statusNames;

    const ecmExpectedResult = calculateTotal(data?.expectedImports);
    const ecmExpectedImports = ecmExpectedResult.statusMap;

    const notMapped = Object.keys(data?.notMapped || {}).length !== 0;
    const notMappedResult = calculateTotal(data?.notMapped);
    const notMappedMPStatuses = notMappedResult.statusMap;
    const notMappedMPStatusesNames = notMappedResult.statusNames;

    return (
      <Grid container>
        <Grid item xs={notMapped ? 4 : 6}>
          <div>Expected MP listings: </div>
          {mpStatusesNames.map(statusName => {
            return (
              <div style={{ marginLeft: "10px" }} key={statusName}>
                {statusName} : {mpStatuses[statusName]}
              </div>
            );
          })}
        </Grid>
        <Grid item xs={notMapped ? 4 : 6}>
          <div>Expected ECM Inventory: </div>
          {ecmStatusesNames.map(statusName => {
            return (
              <div style={{ marginLeft: "10px" }} key={statusName}>
                {statusName} :{" "}
                {ecmExpectedImports[statusName]
                  ? `${ecmStatuses[statusName]} + ${
                      ecmExpectedImports[statusName]
                    } = ${
                      ecmStatuses[statusName] + ecmExpectedImports[statusName]
                    }`
                  : ecmStatuses[statusName]}
              </div>
            );
          })}
        </Grid>
        <Grid item xs={4} display={notMapped ? "block" : "none"}>
          <div>Not Mapped MP listings: </div>
          {notMappedMPStatusesNames.map(statusName => {
            return (
              <div style={{ marginLeft: "10px" }} key={statusName}>
                {statusName} : {notMappedMPStatuses[statusName]}
              </div>
            );
          })}
        </Grid>
      </Grid>
    );
  };

  const calculateTotal = (data: { [status: string]: number }) => {
    const statusMap = data || {};
    const statusNames = Object.getOwnPropertyNames(statusMap);

    if (
      statusNames.length &&
      !statusNames.some(
        statusName => statusName.toLocaleLowerCase() === "total",
      )
    ) {
      statusMap.total = statusNames.reduce(
        (total, status) => total + statusMap[status],
        0,
      );
      statusNames.push("total");
    }

    return { statusMap, statusNames };
  };

  const displayData = () => {
    let result = null;

    switch (item.type) {
      case "MarketplaceInventory":
        result = displayMarketplaceInventory(item?.data);
        break;
      case "EcmInventory":
        result = displayEcmInventory(item?.data);
        break;
      case "RemoveListings":
        result = displayRemoveProducts(item?.data);
        break;
      case "ImportProducts":
        result = importProducts(item?.data);
        break;
      case "ExtractListings":
        result = extractListings(item?.data);
        break;
      case "ExpectedResults":
        result = expectedResults(item?.data);
        break;
      case "ImportAnalysis":
        result = displayImportAnalysis(item?.data);
        break;
      case "CSVBoxImport":
        result = DisplayCSVBoxImport(item.ecmEnv, item?.data, "CSV box");
        break;
      case "AnonymousMarketplaceImport":
        result = DisplayCSVBoxImport(item.ecmEnv, item?.data, "Anonymous marketplace import");
        break;
      default:
        result = JSON.stringify(item.data);
        break;
    }

    return result;
  };

  const isCompleteReportDisabled = item.data.completeReportStatus !== "success";
  const isErrorsReportDisabled = item.data.errorsReportStatus !== "success";
  const isErrorNoneStatus = item.data.errorsReportStatus === "none";
  const isCompleteReportPending = item.data.completeReportStatus === "pending";
  const isErrorsReportPending = item.data.errorsReportStatus === "pending";
  const isExtractedProductFileDisabled =
    item.data.reportFileStatus !== "created";
  const isExtractedProductFailedStatus =
    item.data.reportFileStatus === "failed";
  let splitButtonTooltip = "";
  const apiService = new ApiService();

  if (isDownloadingReport) {
    splitButtonTooltip = getDisabledReportTooltip("loading");
  } else if (isErrorNoneStatus) {
    splitButtonTooltip = getDisabledReportTooltip("error_and_none");
  } else if (isCompleteReportPending && isErrorsReportPending) {
    splitButtonTooltip = getDisabledReportTooltip(
      "complete_and_errors_pending",
    );
  } else {
    splitButtonTooltip = getDisabledReportTooltip("both_error");
  }

  const callTerminateJob = () => {
    apiService
      .terminateJob(
        item.ecmEnv,
        item.projectId,
        item.accountId,
        item.id,
        (item.data as any).jobId,
      )
      .then(() => {
        window.location.reload();
      })
      .catch(err =>
        setAlert({
          type: ALERT_STATUS.INFO,
          message:
            // will be "job was terminated successfully" in the future
            `There was an error while trying to terminate a job: ${err}`,
        }),
      );
  };

  return (
    <Card sx={{ margin: "10px", borderWidth: "3px" }} variant="outlined">
      <Grid container justifyContent="space-between" sx={{ padding: "8px" }}>
        <Grid item xs={6}>
          <Typography textAlign="start">
            {item.createdAt
              ? DateFns.format(
                  DateFns.fromUnixTime(
                    item.createdAt > 1e10
                      ? item.createdAt / 1000
                      : item.createdAt,
                  ),
                  "HH:mma, dd MMM yyyy",
                )
              : "N/A"}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography textAlign="end">
            {item.type ? item.type : "N/A"}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography textAlign="start">
            Status:&nbsp;{item.status ? item.status : "N/A"}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography textAlign="end">
            Duration:&nbsp;
            {formatDuration(item.createdAt, item.finishedAt)}
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <CardContent>
        <Grid
          container
          color="text.secondary"
          style={{ overflowWrap: "anywhere" }}
        >
          <Grid item xs={12}>
            {displayData()}
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions style={{ justifyContent: "space-between" }}>
        <Typography>
          &nbsp;&nbsp;
          {item && item.data && (item.data as any).jobId
            ? `Job id: ${(item.data as any).jobId}`
            : ""}
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
        {
          item.type === "ImportProducts" &&
          ["failed", "finished", "terminated"].includes(item.status) ? (
            <SplitButton
              icon={<FileDownloadIcon />}
              disabledTooltip={splitButtonTooltip}
              disabled={
                isDownloadingReport ||
                (isCompleteReportDisabled &&
                  isErrorsReportDisabled &&
                  isExtractedProductFileDisabled)
              }
              style={{ marginRight: "10px" }}
            >
              <SplitOption
                disabledTooltip={getDisabledReportTooltip(
                  isCompleteReportPending ? "complete_pending" : "complete",
                )}
                disabled={isCompleteReportDisabled}
                onClick={() => downloadReport([DownloadReportType.COMPLETE])}
              >
                Complete report
              </SplitOption>
              {generatePresignedUrl ? (
                <SplitOption
                  disabled={isExtractedProductFileDisabled}
                  disabledTooltip={getDisabledReportTooltip(
                    isExtractedProductFailedStatus
                      ? "extracted_products_failed"
                      : "extracted_products_pending",
                  )}
                  onClick={() => generatePresignedUrl(item.id)}
                >
                  Extracted products file
                </SplitOption>
              ) : null}
              <SplitOption
                disabledTooltip={getDisabledReportTooltip(
                  isErrorNoneStatus
                    ? "errors_none"
                    : isErrorsReportPending
                    ? "errors_pending"
                    : "errors_failed",
                )}
                disabled={isErrorsReportDisabled}
                onClick={() => downloadReport([DownloadReportType.ERRORS])}
              >
                Errors report
              </SplitOption>
              <SplitOption
                disabled={isCompleteReportDisabled || isErrorsReportDisabled}
                disabledTooltip="One of the report is not available"
                onClick={() =>
                  downloadReport([
                    DownloadReportType.COMPLETE,
                    DownloadReportType.ERRORS,
                  ])
                }
              >
                Complete & errors report
              </SplitOption>
            </SplitButton>
          ) : null
        }
        <Button
          size="small"
          onClick={() => setExpanded(!expanded)}
          disabled={!item.logs || !item.logs.length}
        >
          {!expanded ? "Show Logs" : "Hide Logs"}
        </Button>

        {item.type === "ImportProducts" && ["pending"].includes(item.status) && (
          <Button size="small" onClick={() => callTerminateJob()}>
            &#10006;
          </Button>
        )}
      </div>
      </CardActions>
      {isDownloadingReport ? (
        <LinearProgress style={{ height: "5px" }} />
      ) : null}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider />
        <CardContent>
          {item.logs?.map((log: any, index: number) => (
            <Typography key={index} color={log.isError ? "error" : ""}>
              {index + 1}.{log.message}
            </Typography>
          ))}
        </CardContent>
      </Collapse>
    </Card>
  );
}
