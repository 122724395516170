import { CSVBoxButton } from "@csvbox/react";
import { Button } from "@mui/material";

interface CSVBoxModalButtonProps {
  userId: string;
  licenseKey: string;
  dealerId: string;
  jobId: string;
  env: string;
  onFinish?: () => void;
}

interface EnvConfig {
  env_name: string;
  base_url: string;
  token: string;
}

const envConfigs: Record<string, EnvConfig> = {
  qa: {
    env_name: "qa",
    base_url: process.env.REACT_APP_CSVBOX_BASE_URL_QA,
    token: process.env.REACT_APP_CSVBOX_AUTH_TOKEN_QA,
  },
  staging: {
    env_name: "staging",
    base_url: process.env.REACT_APP_CSVBOX_BASE_URL_STAGING,
    token: process.env.REACT_APP_CSVBOX_AUTH_TOKEN_STAGING,
  },
  prod: {
    env_name: "prod",
    base_url: process.env.REACT_APP_CSVBOX_BASE_URL_PROD,
    token: process.env.REACT_APP_CSVBOX_AUTH_TOKEN_PROD,
  },
  default: {
    env_name: "dev",
    base_url: process.env.REACT_APP_CSVBOX_BASE_URL_DEV,
    token: process.env.REACT_APP_CSVBOX_AUTH_TOKEN_DEV,
  },
};

const getEnvConfig = (env: string) => {
  const key = Object.keys(envConfigs).find(key => env.startsWith(key));
  return envConfigs[key || "default"];
};

export const CSVBoxModalButton = ({
  userId = "",
  licenseKey = "",
  dealerId,
  jobId,
  env = "",
  onFinish,
}: CSVBoxModalButtonProps) => {
  if (!env) {
    return null;
  }

  return (
    <CSVBoxButton
      key={env}
      licenseKey={licenseKey}
      user={{ user_id: userId, dealer_id: dealerId, job_id: jobId }}
      environment={getEnvConfig(env)}
      onImport={(result: any, data: any) => {
        if (result) {
          console.log("success");
          console.log(data.row_success + " rows uploaded");
          console.log("dealer id: " + dealerId);
          console.log("data", data);
        } else {
          console.log("fail", data);
        }
        onFinish?.();
      }}
      render={(launch: any, isLoading: boolean) => {
        return (
          <Button
            fullWidth={true}
            style={{
              height: "100%",
              maxWidth: "200px",
              fontSize: "15px",
              padding: "10px 20px",
              marginTop: "10px",
            }}
            size="small"
            variant="outlined"
            color="primary"
            disabled={isLoading}
            onClick={launch}
          >
            CSV Box Import
          </Button>
        );
      }}
    >
      Import
    </CSVBoxButton>
  );
};
