import { Card, LinearProgress } from "@mui/material";
import { ActionModel } from "import-automation-tool-backend/src/models/action/action.model";
import { MarketplaceNoteModel } from "import-automation-tool-backend/src/models/marketplaceNote.model";

import ActionItem from "./Item";

interface ComponentProps {
  actions: ActionModel[];
  loading: boolean;
  generatePresignedUrl?: (id: string) => void | undefined;
  marketplaceNotes?: MarketplaceNoteModel | null;
  saveMarketplaceNotes?: (note: string) => void | null;
  marketplaceNotesLoading?: boolean | null;
}

export default function ActionList({
  actions,
  loading,
  generatePresignedUrl,
  marketplaceNotes,
  saveMarketplaceNotes,
  marketplaceNotesLoading,
}: ComponentProps) {

  return (
    <Card variant="outlined" sx={{ minHeight: 200 }}>
      {loading && (
        <LinearProgress
          className="progress"
          style={{ height: "5px" }}
          id="actions-progress"
          data-testid="actions-progress"
        />
      )}
      {actions?.map(item => (
        <ActionItem
          key={item.id}
          item={item}
          generatePresignedUrl={generatePresignedUrl}
          marketplaceNotes={marketplaceNotes}
          saveMarketplaceNotes={(note: string) => {
            if (saveMarketplaceNotes) {
              saveMarketplaceNotes(note);
            }
          }}
          marketplaceNotesLoading={marketplaceNotesLoading}
        />
      ))}
    </Card>
  );
}
